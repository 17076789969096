import React, { useContext, useState, useEffect } from "react";

import img_bg from "../../img/R-BG.png";
import img_logo from "./H-logo.png";

import LoadingIcons from "react-loading-icons";

import { UserContext } from "../../context/UserContext";

export default function HarmonyPage() {
  const { user, setUser, loading } = useContext(UserContext);

  if (loading) {
    return (
      <div
        className=" bg-[#020C1D] min-h-screen bg-cover bg-center bg-no-repeat"
        style={{
          width: "full",
          backgroundImage: `url(${img_bg})`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "50px",
          paddingTop: "20px",
        }}
      >
        <LoadingIcons.Bars className="text-white h-20" />
        <p className="text-white mt-10">Loading...</p>
      </div>
    );
  } else if (user.status !== "verified") {
    window.location.href = "/nda";
    return null;
  } else {
    return (
      <div
        className=" bg-[#020C1D] min-h-screen bg-cover bg-center bg-no-repeat"
        style={{
          width: "full",
          backgroundImage: `url(${img_bg})`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "50px",
          paddingTop: "20px",
        }}
      >
        <div
          id="title"
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
          className="mb-4"
        >
          <img
            src={img_logo}
            alt="logo"
            className="h-20 mx-auto cursor-pointer animate__animated animate__slower 2s animate__pulse animate__infinite	infinite"
          />

          <button
            className={
              "card mt-4 w-full px-10 bg-[#020C1D] border-2 border-[#FFA700] shadow-[#cfbe9e] shadow-md h-10 text-white hover:scale-105 duration-300 rounded-md "
            }
            onClick={() => {
              window.location.href = "docs/Information-Memorandum.pdf";
            }}
          >
            <div className="flex items-center justify-center w-full my-auto ">
              <div>
                <p className="my-auto font-semibold md:text-sm text-sm">
                  Information Memorandum
                </p>
              </div>
            </div>
          </button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-20 gap-y-0">
          {chunkArray(listDocs, 10).map((docChunk, chunkIndex) => (
            <div key={`chunk_${chunkIndex}`} className="max-w-xs">
              {docChunk.map((doc) => {
                const docWithoutExtension = doc.split(".pdf")[0];
                const docParts = docWithoutExtension.split("_");
                const docNumber = docParts[0]
                  .replace(/[\(\)]/g, "")
                  .concat(". ");
                const docName = docParts.slice(1).join(" ");
                const formattedDocName = docNumber + docName;

                return (
                  <TheButton
                    link={`/docs/${doc}`}
                    name={formattedDocName}
                    key={doc}
                    // You can add logo and animate props here
                  />
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const listDocs = [
  "(1)_Lismore_Growth_Realignment_Strategy.pdf",
  "(2)_URBIS_Urban_Design_Report.pdf",
  "(3)_Heritage_Due_Diligence_Assessment.pdf",
  "(4)_URBIS_Economic_Benefits_Assessment.pdf",
  "(5)_Preliminary_Civil_Construction_Costs.pdf",
  "(6)_Social_Infrastructure_Needs_Assessment.pdf",
  "(7)_Ecological_Assessment_Report.pdf",
  "(8)_Lismore_Planning_Proposal_Presentation.pdf",
  "(9)_Lismore_Gateway_Endorsement.pdf",
  "(10)_URBIS_Indicative_Staging_Plan.pdf",
  "(11)_Agricultural_Assessment.pdf",
  "(12)_Land_Use_Conflict_Risk_Assessment.pdf",
  "(13)_Bushfire_Constraints_Assessment.pdf",
  "(14)_Detailed_Site_Investigation.pdf",
  "(15)_Acid_Sulfate_Soil_Investigation.pdf",
  "(16)_Noise_Impact_Assessment.pdf",
  "(17)_Geotechnical_Investigations_Report.pdf",
  "(18)_Traffic_and_Transport_Study.pdf",
  "(19)_Water_Servicing_Assessment_(Engeny).pdf",
  "(20)_Sewer_Servicing_Assessment_(H2One).pdf",
  "(21)_Stormwater_Management_Report.pdf",
  "(22)_Site_Survey___Balanced_Earthworks.pdf",
  "(23)_Urban_Growth_Variation_Principles.pdf",
  "(24)_Important_Farmland_Variation_Criteria.pdf",
  "(25)_State_and_regional_studies.pdf",
  "(26)_Consistency_with_the_SEPPS.pdf",
  "(27)_Consistency_with_Section_9.11_Directions.pdf",
  "(28)_Earthworks_and_Slope_Analysis.pdf",
  "(29)_Gateway_Process.pdf",
];

const TheButton = ({ link, name, animate }) => {
  return (
    <button
      className={
        "card my-1 w-full bg-[#FFA700] shadow-2xl h-7 text-[#020C1D] hover:scale-105 duration-300 rounded-md " +
        animate
      }
      onClick={() => {
        window.location.href = link;
      }}
    >
      <div className="flex justify-start w-full content-center text-left my-auto mx-2">
        <div>
          <p className=" my-auto font-semibold md:text-xs text-[10px]">
            {name}
          </p>
        </div>
        <div className=""></div>
      </div>
    </button>
  );
};

function chunkArray(array, chunkSize) {
  let index = 0;
  let tempArray = [];

  for (index = 0; index < array.length; index += chunkSize) {
    tempArray.push(array.slice(index, index + chunkSize));
  }

  return tempArray;
}
